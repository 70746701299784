// this file is temporary created because exisiting ChildNode file is not totally reusable.

import { FC, MouseEvent } from "react";

import StyledTreeItem from "./styledTreeItem";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import { pxToRem } from "../../../util";
import { Colors } from "../../../configs/colors";

export interface ITreeTempoChildNodeProps {
  nodeID: string;
  instructions?: string[];
  details: (string | undefined)[];
  upcharges?: string[];
  qty: number;
  total: number;
  selected: boolean;
  onToggleSelect: (id: string) => void;
  disabled: boolean;
}

const TreeTempoChildNode: FC<ITreeTempoChildNodeProps> = ({
  nodeID,
  instructions,
  details,
  upcharges,
  qty,
  total,
  selected,
  onToggleSelect,
  disabled,
}) => {
  const handleComponentClick = (event: MouseEvent) => {
    event.stopPropagation();
  };
  return (
    <StyledTreeItem
      nodeId={nodeID}
      label={
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1.53857143rem",
              minWidth: "52.1428571rem",
              maxWidth: "52.1428571rem",
            }}
          >
            <Checkbox
              checked={disabled ? false : selected}
              onChange={(e) => onToggleSelect(nodeID)}
              onClick={handleComponentClick}
              disabled={disabled}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  lineHeight: "1.2rem",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "46.4285714rem",
                  whiteSpace: "nowrap",
                }}
              >
                <span
                  style={{
                    textDecoration: disabled ? "line-through" : "none",
                    color: disabled
                      ? Colors.ACCENT_GRAY_DISABLED
                      : Colors.TEXT_GRAY_DARK,
                  }}
                >
                  {details
                    .map((item, index) => {
                      if (item === undefined) {
                        return;
                      }
                      return item;
                    })
                    .join(", ")}
                  {(instructions?.length || 0) > 0
                    ? "&nbsp; + Instructions-"
                    : ""}{" "}
                  {instructions?.map((instruction, index) => {
                    return (
                      <>
                        {instruction}
                        {index < instructions.length - 1 ? ", " : ""}
                      </>
                    );
                  })}
                </span>
                &nbsp;&nbsp;&nbsp;
                {disabled ? "Picked Up" : ""}
              </Typography>
              {(upcharges?.length || 0) > 0 && (
                <Typography
                  sx={{
                    // "&::before": { content: `"+ "` },
                    fontSize: "1rem",
                    lineHeight: "1.2rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "46.4285714rem",
                    whiteSpace: "nowrap",
                    textDecoration: disabled ? "line-through" : "none",
                    color: disabled
                      ? Colors.ACCENT_GRAY_DISABLED
                      : Colors.TEXT_GRAY_DARK,
                  }}
                >
                  {(upcharges?.length || 0) > 0 ? "&nbsp; + Upcharges-" : ""}{" "}
                  {upcharges?.map((upcharge, index) => {
                    return (
                      <>
                        {upcharge}
                        {index < upcharges.length - 1 ? ", " : ""}
                      </>
                    );
                  })}
                </Typography>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: pxToRem(20),
              ml: pxToRem(-300),
            }}
          >
            <Typography
              sx={{
                fontSize: pxToRem(14),
                textAlign: "center",
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              x{qty}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: pxToRem(20),
              mr: pxToRem(30),
            }}
          >
            <Typography
              sx={{
                fontSize: pxToRem(14),
                textAlign: "center",
                color: Colors.TEXT_GRAY_DARK,
              }}
            >
              ${total}
            </Typography>
          </Box>
        </Box>
      }
      type="child"
      sx={{
        "& > .MuiTreeItem-content": {
          background: Colors.WHITE,
          width: "calc(100% - 2.21428571rem)",
          marginLeft: "2.21428571rem",
          height: "3.85714286rem",
          borderRadius: "0.428571429rem",
          display: "flex",
          alignItems: "center",
          py: "0.75rem",
          px: "1.2696428571428rem",
          pl: "0rem",
          boxSizing: "border-box",
          border: `0.0714285714rem solid ${Colors.ACCENT_GRAY_DISABLED}`,
          "&:hover": {
            background: Colors.ACCENT_GRAY,
          },
          my: "0.142857143rem",
        },
      }}
    />
  );
};

export default TreeTempoChildNode;
