import { FC, useEffect, useState } from "react";
import { Box, Typography, Divider, CircularProgress } from "@mui/material";
import { AddCircleOutline, ExpandMore, ExpandLess } from "@mui/icons-material";
import { TreeView } from "@mui/x-tree-view";

import CustomModal from "../../../components/common/custom-modal/custom-modal";
import { CustomButton } from "../../../components";
import TempoParentNode from "../../../components/atoms/tree-view/TemporaryParentNode"; //temporary added until parentNode is made as reusable
import TempoChildNode from "../../../components/atoms/tree-view/TemporaryChildNode"; //temporary added until childNode is made as reusable
import { formatCurrency, getTenantId, pxToRem } from "../../../util";
import { Colors } from "../../../configs";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { ICartSession } from "../../../typings/interfaces/pos/cart-session";
import { CartItem } from "../../../typings/interfaces/pos/cart-item";
import { useSnackbarContext } from "../../../providers/SnackbarProvider";
import PaymentModal, {
  IPaymentModalData,
} from "../../../components/pos/payment-modal/payment-modal";
import { PaymentModalContents } from "../../../typings/enum/paymentModalContents";
import Payment from "../../../components/pos/payment-modal/payment";
import {
  BillPaymentStatus,
  BillPickupStatus,
  PaymentTypeEnum,
} from "../../../typings/enum/paymentTypes";
import {
  getBillItemIdListAction,
  newPaymentAction,
  pickUpItemAction,
} from "../../../redux/actions/cartAction";
import { BaseResponseSingle } from "../../../typings";
import { PaymentCreateResponse } from "../../../typings/interfaces/responses/paymentResponse";
import { CartSliceAction } from "../../../redux/slice/cartSlice";
import { IPaymentRequest } from "../../../typings/interfaces/requests";
import { useParams } from "react-router-dom";
import useCart from "../../../hooks/useCart";
import { store } from "../../../redux/store";
import { IHalfPayment } from "../../../typings/interfaces/payment/halfPayment";
import ItemNoteModal from "../../../components/pos/notes-modal/itemNoteModal";
import {
  getCustomerBillHistoryAction,
  getCustomerByIdAction,
} from "../../../redux/actions/customerAction";
import { number } from "yup";

interface IPickupModalProps {
  open: boolean;
  onClose: () => void;
}

enum PickupType {
  PRE_PAY_NOT_PICKUP = "Pre Pay - Not Pickup",
  PAY_AND_PICKUP = "Pay & Pickup",
  NOT_PAY_PICKUP = "Not Pay - Pickup",
}

interface IPickupCartItem extends CartItem {
  selected: boolean;
}
interface IPickupCartBill extends ICartSession {
  cartItems: IPickupCartItem[];
  billId: string;
  id: number;
  discountAmount: number;
  billAmount: number;
  paidAmount: number | null;
  totalItemCount: number;
  pickingFilterStatus: BillPickupStatus;
  paymentFilterStatus: BillPaymentStatus;
}

const PickupModal: FC<IPickupModalProps> = ({ open, onClose }) => {
  const {
    cartHistorySelectedRows,
    paymentHalfDetails,
    billItemIdList,
    billItemIdListLoading,
  } = useAppSelector((state) => state.cart);

  const [preparedData, setPreparedData] = useState<IPickupCartBill[]>([]);
  const [pickupType, setPickupType] = useState<PickupType>(
    PickupType.PRE_PAY_NOT_PICKUP
  );
  const [modalType, setModalType] = useState<"pickup" | "payment">("pickup");

  const [intiaLRender, setIntiaLRender] = useState(true);

  const [paymentType, setPaymentType] = useState<PaymentTypeEnum>(
    PaymentTypeEnum.CASH
  );

  const paidAmount = paymentHalfDetails.reduce(
    (acc, item) => acc + item.halfPaymentAmount,
    0
  );

  const {
    getSessionTotalBySessionId,
    getSessionDiscountBySession,
    getSessionDiscountById,
    getSessionTotalBySession,
    cartTotal,
    totalDiscountAmount,
  } = useCart();

  const snackbar = useSnackbarContext();
  const tenantId = getTenantId();
  const dispatch = useAppDispatch();
  const params = useParams();
  const customerId = params["id"];

  useEffect(() => {
    dispatch(CartSliceAction.clearSession());
  }, []);

  // on page unmount, clear the session
  useEffect(() => {
    return () => {
      dispatch(CartSliceAction.clearSession());
    };
  }, []);

  useEffect(() => {
    setPreparedData(
      cartHistorySelectedRows.map((rowBill) => {
        const temp: ICartSession = JSON.parse(
          rowBill.billString
        ) as ICartSession;
        const cartItems: IPickupCartItem[] = temp.cartItems.map((cartItem) =>
          prepareCartItem(cartItem, rowBill.id)
        );
        const temp2: IPickupCartBill = temp as IPickupCartBill;
        temp2.cartItems = cartItems;
        temp2.billId = rowBill.billId;
        temp2.id = rowBill.id;
        temp2.discountAmount = rowBill.discountAmount ?? 0;
        temp2.billAmount = rowBill.billAmount;
        temp2.paidAmount = rowBill.paidAmount;
        temp2.totalItemCount = rowBill.totalItemCount;
        temp2.pickingFilterStatus =
          rowBill.pickingFilterStatus as BillPickupStatus;
        temp2.paymentFilterStatus =
          rowBill.paymentFilterStatus as BillPaymentStatus;
        return temp2;
      })
    );
  }, [billItemIdList, cartHistorySelectedRows]);

  useEffect(() => {
    if (
      Number(paidAmount.toFixed(2)) >=
      Number(
        preparedData.reduce((tot, bill) => tot + bill.billAmount, 0).toFixed(2)
      )
    ) {
      if (intiaLRender) {
        // this is used to load the actiontile modal only after checkout
        setIntiaLRender(false);
        return;
      }
      onClose();
      if (
        (paymentHalfDetails.length === 0
          ? 0
          : paymentHalfDetails[paymentHalfDetails.length - 1]
              .halfPaymentAmount) >=
          (paymentHalfDetails.length === 0
            ? 0
            : paymentHalfDetails[paymentHalfDetails.length - 1]
                .fullpaymentAmount) -
            totalDiscountAmount &&
        paidAmount !== 0
      ) {
        if (pickupType === PickupType.PAY_AND_PICKUP) {
          handlePickupSubmit();
          // setIntiaLRender(true);
        } else {
          setModalType("pickup");
          onClose();
          dispatch(CartSliceAction.setRefreshCustomerHistory(true));
        }
      }
    }
  }, [paidAmount, paymentHalfDetails]);

  if (!customerId) {
    snackbar.showSnackbar("Customer ID not found", "error");
    onClose();
    return <></>;
  }
  const prepareCartItem = (
    cartItem: CartItem,
    billId: number
  ): IPickupCartItem => {
    return {
      ...cartItem,
      selected: !(
        billItemIdList
          .find((billItem) => billItem.billId === billId)
          ?.billItemIdList.find((item) => item.hashId === cartItem.itemId)
          ?.pickupStatus === BillPickupStatus.PICKED_UP
      ),
    };
  };

  const toggleSelect = (parentId: number, cartItemId: string) => {
    const updatedData = preparedData.map((bill) => {
      if (bill.id !== parentId) {
        return bill;
      }
      bill.cartItems = bill.cartItems.map((cartItem) => {
        if (cartItem.itemId === cartItemId) {
          return { ...cartItem, selected: !cartItem.selected };
        }
        return cartItem;
      });
      return bill;
    });
    setPreparedData(updatedData);
  };

  const handlePartialPickupToggle = (billId: number, newStatus: boolean) => {
    const updatedData = preparedData.map((parent, index) => {
      if (parent.id === billId) {
        parent.cartItems = parent.cartItems.map((cartItem) => {
          if (
            billItemIdList
              .find((billItem) => billItem.billId === parent.id)
              ?.billItemIdList.find((item) => item.hashId === cartItem.itemId)
              ?.pickupStatus === BillPickupStatus.PICKED_UP
          ) {
            return cartItem;
          }

          return { ...cartItem, selected: !newStatus };
        });
      }
      return parent;
    });
    setPreparedData(updatedData);
  };

  const onSubmit = async () => {
    if (
      pickupType === PickupType.PRE_PAY_NOT_PICKUP ||
      pickupType === PickupType.PAY_AND_PICKUP
    ) {
      setModalType("payment");
    } else {
      // Working no Issue
      handlePickupSubmit();
    }
  };

  // Working, No issue
  const handlePickupSubmit = async () => {
    if (!tenantId) {
      snackbar.showSnackbar("Tenant ID not found", "error");
      return;
    }
    for (const item of preparedData) {
      try {
        const b = billItemIdList.find(
          (billItem) => item.id === billItem.billId
        );

        if (!b) {
          snackbar.showSnackbar(
            "Bill item ID not found. Possibly this is an old bill",
            "error"
          );
          continue;
        }

        const ItemIds = item.cartItems.map((cartItem) => {
          if (
            !b.billItemIdList.find(
              (billItem) => billItem.hashId === cartItem.itemId
            )
          ) {
            throw new Error(
              "Bill item ID not found. Possibly this is an old bill"
            );
          }
          return b.billItemIdList.find(
            (billItem) => billItem.hashId === cartItem.itemId
          )!.billItemId;
        });

        const res = await dispatch(
          pickUpItemAction({
            billId: item.id,
            itemIds: ItemIds,
          })
        ).unwrap();

        if (!res) {
          throw new Error("Pickup failed");
        }
        snackbar.showSnackbar("Pickup successful", "success");
        dispatch(CartSliceAction.setRefreshCustomerHistory(true));
        setModalType("pickup");
        onClose();
      } catch (e: any) {
        snackbar.showSnackbar(e.message, "error");
      }
    }
  };

  const handleOnPaymentSubmit = async (data: IPaymentModalData) => {
    if (!tenantId) {
      snackbar.showSnackbar("Tenant ID not found", "error");
      return;
    }
    if (!customerId) {
      snackbar.showSnackbar("Customer ID not found", "error");
      return;
    }

    // this must calculated from paymentHalfDetails, not from the preparedData, because half payments won't refresh the preparedData
    const totalPaidAmount = paymentHalfDetails.reduce(
      (acc, curr) => acc + curr.halfPaymentAmount,
      0
    );

    const totalBillAmount = preparedData.reduce(
      (tot, bill) => tot + bill.billAmount - (bill.paidAmount ?? 0),
      0
    );
    const isFullPaymentDone = totalPaidAmount >= totalBillAmount;

    if (isFullPaymentDone) {
      // if (pickupType !== PickupType.PRE_PAY_NOT_PICKUP) {
      //   handlePickupSubmit();
      // }
      snackbar.showSnackbar("Payment already made", "warning");
      return;
    }

    if (paymentHalfDetails.length === 0) {
      dispatch(
        CartSliceAction.setHalfPayementDetails(
          preparedData.map((bill) => ({
            fullpaymentAmount: bill.billAmount,
            halfPaymentAmount: 0,
            billId: bill.id,
            customerId: customerId,
            payments: [],
          }))
        )
      );

      makePayment(
        data.paymentMode,
        data.paidAmount,
        Number(customerId),
        preparedData.map((bill) => ({
          fullpaymentAmount: bill.billAmount,
          halfPaymentAmount: 0,
          billId: bill.id,
          customerId: Number(customerId),
          payments: [],
        }))
      );
    } else {
      makePayment(
        data.paymentMode,
        data.paidAmount,
        Number(customerId),
        paymentHalfDetails
      );
    }
  };

  const makePayment = async (
    paymentType: PaymentTypeEnum,
    totalPaid: number,
    customerId: number,
    halfPaymentProp: IHalfPayment[]
  ) => {
    let remaining = totalPaid;

    for (const bill of halfPaymentProp) {
      if (remaining <= 0) break;

      const billPayableAmount = bill.fullpaymentAmount - bill.halfPaymentAmount;

      if (bill.fullpaymentAmount <= bill.halfPaymentAmount) continue;

      const paymentAmount = Math.min(
        remaining,
        Number(billPayableAmount.toFixed(2))
      );

      const paymentRequest: IPaymentRequest = {
        paymentType: paymentType,
        billId: bill.billId!,
        customerId: customerId,
        amount: Number(paymentAmount.toFixed(2)),
      };

      await updateHalfOrFullPayment(paymentRequest, {
        paymentMode: paymentType,
        paidAmount: paymentAmount,
      });

      remaining -= paymentAmount;
    }
  };

  const updateHalfOrFullPayment = async (
    paymentRequest: IPaymentRequest,
    paymentModalData: IPaymentModalData
  ) => {
    dispatch(
      newPaymentAction({
        paymentType: paymentModalData.paymentMode,
        billId: paymentRequest.billId,
        customerId: paymentRequest.customerId,
        amount: Number(paymentModalData.paidAmount.toFixed(2)), // to fix floating-point precision errors due to js IEEE 754 standard
      })
    ).then(async (res) => {
      const payload = res?.payload as BaseResponseSingle<PaymentCreateResponse>;

      const updatedPaymentHalfDetails =
        store.getState().cart.paymentHalfDetails;

      const updatedHalfPayment = JSON.parse(
        JSON.stringify(
          // clone deep gave unexpected behavior so that's why used this method
          updatedPaymentHalfDetails.find(
            (item: IHalfPayment) => item.billId === payload.body.billId
          )
        )
      ) as IHalfPayment;
      if (updatedHalfPayment) {
        updatedHalfPayment.halfPaymentAmount += payload.body.amount;
        updatedHalfPayment.payments.push(payload.body);
        try {
          dispatch(
            CartSliceAction.setHalfPayementDetails([
              updatedHalfPayment,
              ...updatedPaymentHalfDetails.filter(
                (item: IHalfPayment) => item.billId !== payload.body.billId
              ),
            ])
          );
          snackbar.showSnackbar("Payment successful", "success");
          // if (pickupType === PickupType.PAY_AND_PICKUP) {
          //   handlePickupSubmit();
          // }
          // dispatch(CartSliceAction.setRefreshCustomerHistory(true));
          // don't need to refresh customer history here, because refreshing the customer history will reset the halfPayment
        } catch (err: any) {
          snackbar.showSnackbar(`Failed to update payment`, "error");
        }
      } else {
        snackbar.showSnackbar(
          "Failed to find bill's saved half payment",
          "error"
        );
      }
    });
  };

  return (
    <CustomModal
      title="Pickup"
      openModal={open}
      onClose={() => {
        setModalType("pickup");
        onClose();
      }}
      width={pxToRem(1263)}
      height={pxToRem(900)}
    >
      {modalType === "pickup" && (
        <>
          {/* Top tree structure box */}
          <Box
            sx={{
              width: pxToRem(1132),
              height: pxToRem(429),
              backgroundColor: Colors.WHITE,
              border: `${pxToRem(1)} solid ${Colors.ACCENT_GRAY}`,
              borderRadius: pxToRem(5),
              marginBottom: pxToRem(10),
              padding: pxToRem(10),
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": { width: "0.5rem" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#ADADAD",
                borderRadius: "0rem",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
                borderRadius: "0rem",
              },
            }}
          >
            <TreeView
              aria-label="pickup-list"
              defaultExpandIcon={<ExpandMore />}
              defaultCollapseIcon={<ExpandLess />}
              sx={{
                overflowX: "hidden",
                gap: pxToRem(15),
                display: "flex",
                flexDirection: "column",
              }}
            >
              {billItemIdListLoading && (
                <CircularProgress
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  size={pxToRem(50)}
                />
              )}
              {!billItemIdListLoading &&
                preparedData.map((parent, index) => (
                  <TempoParentNode
                    key={index}
                    nodeID={String(index)}
                    service={parent.cartItems[0].serviceType!?.categoryName}
                    qty={parent.cartItems.reduce(
                      (acc, curr) => acc + curr.qty,
                      0
                    )}
                    total={
                      getSessionTotalBySession(parent) -
                      getSessionDiscountBySession(parent)
                    }
                    partialPickup={parent.cartItems.some(
                      (item) => item.selected === false
                    )}
                    onTogglePartialPickup={(newStatus) => {
                      handlePartialPickupToggle(parent.id, newStatus);
                    }}
                  >
                    {parent.cartItems.map((cartItem, index) => (
                      <TempoChildNode
                        key={index}
                        nodeID={cartItem.itemId}
                        qty={cartItem.qty}
                        total={
                          cartItem.qty * cartItem.unitPrice +
                          cartItem.upcharges.reduce(
                            (acc, curr) => acc + curr.subUpcharge.price,
                            0
                          ) +
                          cartItem.spots.reduce(
                            (acc, curr) => acc + (curr.subSpot.price ?? 0),
                            0
                          )
                        }
                        disabled={
                          billItemIdList
                            .find((billItem) => billItem.billId === parent.id)
                            ?.billItemIdList.find(
                              (item) => item.hashId === cartItem.itemId
                            )?.pickupStatus === BillPickupStatus.PICKED_UP
                        }
                        details={[
                          cartItem.garmentType?.name,
                          cartItem.garmentColor
                            ?.map((color) =>
                              color
                                .replace(/_/g, " ")
                                .replace(/(\b[a-z](?=[a-z]{1}))/g, (x) =>
                                  x.toUpperCase()
                                )
                            )
                            .join(", "),
                          cartItem.materialType?.name,
                          cartItem.texture,
                          cartItem.garmentStyle?.name,
                        ]}
                        selected={cartItem.selected}
                        onToggleSelect={() =>
                          toggleSelect(parent.id, cartItem.itemId)
                        }
                      />
                    ))}
                  </TempoParentNode>
                ))}
            </TreeView>
          </Box>

          {/* Mid Container */}
          <Box
            sx={{
              width: pxToRem(1132),
              height: pxToRem(54),
              backgroundColor: Colors.BACKSHADE_GRAY,
              border: `${pxToRem(1)} solid ${Colors.ACCENT_GRAY}`,
              borderRadius: pxToRem(5),
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: `0 ${pxToRem(30)}`,
              marginY: pxToRem(15),
            }}
          >
            <Typography
              sx={{
                color: Colors.TEXT_GRAY_DARK,
                fontSize: pxToRem(20),
                fontWeight: "bold",
              }}
            >
              Number of pieces:{" "}
              {preparedData.reduce((tot, bill) => tot + bill.totalItemCount, 0)}
              {/* selected items count */}
              {/* {preparedData.reduce(
                (tot, bill) =>
                  tot +
                  bill.cartItems.filter((cartItem) => cartItem.selected).length,
                0
              )} */}
            </Typography>
            <Typography
              sx={{
                color: Colors.TEXT_GRAY_DARK,
                fontSize: pxToRem(20),
                display: "flex",
                justifyContent: "space-between",
                width: pxToRem(407),
              }}
            >
              <span>Bill Total</span>{" "}
              <span>
                {formatCurrency({
                  amount: preparedData.reduce(
                    (tot, bill) => tot + bill.billAmount + bill.discountAmount,
                    0
                  ),
                  prefix: "$",
                })}
              </span>
            </Typography>
          </Box>

          <Divider
            sx={{
              width: pxToRem(1132),
              height: pxToRem(2),
              backgroundColor: Colors.PRIMARY,
              marginY: pxToRem(15),
            }}
          />

          {/* Bottom Section */}
          <Box
            sx={{
              display: "flex",
              width: pxToRem(1132),
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: pxToRem(20),
                rowGap: pxToRem(30),
                width: "auto",
              }}
            >
              {Object.values(PickupType).map((label, index) => (
                <CustomButton
                  key={label}
                  sx={{
                    width: pxToRem(282),
                    height: pxToRem(78),
                    fontSize: pxToRem(22),
                    borderRadius: pxToRem(10),
                    backgroundColor:
                      pickupType === label ? Colors.PRIMARY : Colors.WHITE,
                    color: pickupType === label ? Colors.WHITE : Colors.PRIMARY,
                    border:
                      pickupType === label
                        ? "none"
                        : `2px solid ${Colors.PRIMARY}`,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: pxToRem(8),
                  }}
                  onClick={() => setPickupType(label)}
                >
                  {label}
                </CustomButton>
              ))}

              <CustomButton
                sx={{
                  width: pxToRem(282),
                  height: pxToRem(78),
                  fontSize: pxToRem(22),
                  borderRadius: pxToRem(10),
                  backgroundColor: Colors.WHITE,
                  color: Colors.PRIMARY,
                  border: `${pxToRem(2)} solid ${Colors.PRIMARY}`,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: pxToRem(8),
                  "&:disabled": {
                    border: `${pxToRem(2)} solid ${
                      Colors.ACCENT_GRAY_DISABLED
                    }`,
                  },
                }}
                disabled={true}
              >
                <AddCircleOutline
                  sx={{
                    color: "inherit",
                    width: pxToRem(37),
                    height: pxToRem(37),
                  }}
                />
                Retail Items
              </CustomButton>
            </Box>

            <Box
              sx={{
                height: pxToRem(195),
                width: pxToRem(2),
                backgroundColor: Colors.PRIMARY,
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: pxToRem(10),
              }}
            >
              <Box
                sx={{
                  width: pxToRem(465),
                  height: pxToRem(123),
                  border: `${pxToRem(1)} solid ${Colors.PRIMARY}`,
                  borderRadius: pxToRem(7),
                  padding: `${pxToRem(10)} ${pxToRem(25)}`,
                }}
              >
                <Typography
                  sx={{
                    fontSize: pxToRem(20),
                    color: Colors.TEXT_GRAY_DARK,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Total Discounts:</span>{" "}
                  <span>
                    {formatCurrency({
                      amount: preparedData.reduce(
                        (tot, bill) => tot + (bill.discountAmount ?? 0),
                        0
                      ),
                      prefix: "$",
                    })}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: pxToRem(20),
                    color: Colors.TEXT_GRAY_DARK,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>Total Paid:</span>{" "}
                  <span>
                    {formatCurrency({
                      amount: preparedData.reduce(
                        (tot, bill) => tot + (bill.paidAmount ?? 0),
                        0
                      ),
                      prefix: "$",
                    })}
                  </span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: pxToRem(20),
                    fontWeight: "bold",
                    color: Colors.TEXT_GRAY_DARK,
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: pxToRem(10),
                  }}
                >
                  <span>Due Amount:</span>{" "}
                  <span>
                    {formatCurrency({
                      amount: preparedData.reduce(
                        (tot, bill) =>
                          tot + bill.billAmount - (bill.paidAmount ?? 0),
                        0
                      ),
                      prefix: "$",
                    })}
                  </span>
                </Typography>
              </Box>

              <CustomButton
                onClick={onSubmit}
                sx={{
                  width: pxToRem(465),
                  height: pxToRem(60),
                  fontSize: pxToRem(28),
                  backgroundColor: Colors.PRIMARY,
                  color: Colors.WHITE,
                  borderRadius: pxToRem(10),
                }}
                disabled={
                  // empty bill list
                  preparedData.length === 0 ||
                  // selected pickup options and not selected any items
                  (preparedData.every((item) =>
                    item.cartItems.every((cartItem) => !cartItem.selected)
                  ) &&
                    (pickupType === PickupType.PAY_AND_PICKUP ||
                      pickupType === PickupType.NOT_PAY_PICKUP)) ||
                  // selected payment options and due amount is equal or less than 0
                  ((pickupType === PickupType.PRE_PAY_NOT_PICKUP ||
                    pickupType === PickupType.PAY_AND_PICKUP) &&
                    preparedData.reduce(
                      (tot, bill) =>
                        tot + bill.billAmount - (bill.paidAmount ?? 0),
                      0
                    ) <= 0)
                }
              >
                {pickupType === PickupType.PRE_PAY_NOT_PICKUP
                  ? "Proceed to Pay"
                  : pickupType === PickupType.PAY_AND_PICKUP
                  ? "Pay & Pickup"
                  : "Proceed to Pickup"}
              </CustomButton>
            </Box>
          </Box>
        </>
      )}
      {modalType === "payment" && (
        <Payment
          onCheckout={handleOnPaymentSubmit}
          onPaymentTypeChange={(type) => {
            setPaymentType(type);
          }}
          paymentType={paymentType}
          pickupAmount={preparedData.reduce(
            (tot, bill) => tot + bill.billAmount - (bill.paidAmount ?? 0),
            0
          )}
        />
      )}
    </CustomModal>
  );
};

export default PickupModal;
